<template>
  <div>
    <div class="isCertifyBox" v-if="isCertify === 'N'">hcp 인증이 필요한 컨텐츠 입니다.</div>
    <div v-else>
      <!-- 추가 AI LN콘텐츠 -->
      <LN_PrografAICardPage
        ref="refAI_LN"
        v-if="$route.path === '/quickMenu/prografAI_LN'"
        @slideChange="slideChange"
      />

      <!-- 추가 AI RA콘텐츠 -->
      <RA_PrografAICardPage
        ref="refAI_RA"
        v-if="$route.path === '/quickMenu/prografAI_RA'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Tx 뉴스 클리핑 04월  -->
      <PrografTx_NewsClipping_April_CardPage
        ref="refPrografTx_NewsClipping_April"
        v-if="$route.path === '/quickMenu/PrografTx_NewsClipping_April'"
        @slideChange="slideChange"
      />

      <!-- 추가 TX PrografTX -->
      <PrografTX ref="ref_PrografTX" v-if="$route.path === '/quickMenu/prografTX'" @slideChange="slideChange" />


      <!-- 베타미가 학술대회 (08-12) 퀵메뉴 -->
      <AstellasBetmigaSympo08CardPage
        ref="refAstellasBetmigaSympo08"
        v-if="$route.path === '/quickMenu/AstellasBetmigaSympo08'"
        @slideChange="slideChange"
      />

      <!-- 베타미가 학술대회 (09-12) 퀵메뉴 -->
      <AstellasBetmigaSympo09CardPage
        ref="refAstellasBetmigaSympo09"
        v-if="$route.path === '/quickMenu/AstellasBetmigaSympo09'"
        @slideChange="slideChange"
      />

      <!-- 베타미가 학술대회 (09-12) 퀵메뉴 -->
      <AstellasBetmigaSympo09CardPage
          ref="refAstellasBetmigaSympo09"
          v-if="$route.path === '/quickMenu/AstellasBetmigaSympo09Temp'"
          @slideChange="slideChange"
      />

      <!-- 베타미가 컨텐츠 (24년 08월) 퀵메뉴 -->
      <Betamiga2408CardPage
        ref="refBetamiga2408"
        v-if="$route.path === '/quickMenu/Betamiga2408'"
        @slideChange="slideChange"
      />

      <!-- 하루날디 컨텐츠 (24년 08월) 퀵메뉴 -->
      <HanalD2408CardPage
        ref="refHanalD2408"
        v-if="$route.path === '/quickMenu/HanalD2408'"
        @slideChange="slideChange"
      />

      <!-- 엑스탄디 컨텐츠 (25년 01월) 퀵메뉴 -->
      <Xtandi2501CardPage
          ref="refXtandi2501"
          v-if="$route.path === '/quickMenu/Xtandi2501'"
          @slideChange="slideChange"
      />

    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { mapActions, mapGetters } from 'vuex';
import RA_PrografAICardPage from './comp/RA_PrografAICardPage.vue';
import LN_PrografAICardPage from './comp/LN_PrografAICardPage.vue';
import PrografTX from './comp/PrografTXCardPage.vue';
import PrografTx_NewsClipping_April_CardPage from './friendsComp/PrografTx_NewsClipping_April_CardPage.vue';
import AstellasBetmigaSympo08CardPage from './comp/AstellasBetmigaSympo08CardPage.vue';
import AstellasBetmigaSympo09CardPage from './comp/AstellasBetmigaSympo09CardPage.vue';
import Betamiga2408CardPage from './comp/Betamiga2408CardPage.vue';
import HanalD2408CardPage from './comp/HanalD2408CardPage.vue';
import Xtandi2501CardPage from './comp/Xtandi2501CardPage.vue';

export default {
  components: {
    swiper,
    swiperSlide,
    RA_PrografAICardPage,
    LN_PrografAICardPage,
    PrografTx_NewsClipping_April_CardPage,
    PrografTX,
    AstellasBetmigaSympo08CardPage,
    AstellasBetmigaSympo09CardPage,
    Betamiga2408CardPage,
    HanalD2408CardPage,
    Xtandi2501CardPage,
  },
  data() {
    return {
      isCertify: 'N',
      vendor: '',
      userAgent: '',
      alertText: '',
      mobile4info: '',
    };
  },
  created() {
    console.log('isCertifyCard', this.isCertifyCard);
    this.isCertify = 'N';
    if (this.isCertifyCard) {
      this.isCertify = 'Y';
    }
  },
  methods: {
    // 로그 쌓는 경로 변경 처리(처리안함)
    slideChange(data) {
      console.log('slideChange', data);
      // this.content = data.content;
      // this.page = data.page;
      // this.link = data.tLink;
      // this.kakaoChannel = this.$route.query.kakaoChannel;
      // this.kakaoUserId = this.$route.query.kakaoId;
      // this.saveViewContentLog(this).then(() => {});
    },

    ...mapActions(['saveViewContentLog', 'saveFriendsContentLog', 'getHcpInfo', 'getHcpInfoByMobile4']),
  },
  computed: {
    ...mapGetters(['isCertifyCard']),
  },
};
</script>
<style lang="scss">
.isCertifyBox {
  width: 100%;
  height: calc(100vh - 85px);
  background: white;
  text-align: center;
  padding: 150px 0;
}
</style>
